/* Fonts */

.h1 {
    font-size: 52px;
    font-family: 'CircularBold';
}

.h2 {
    font-size: 28px;
    font-family: 'CircularBold';
}

.largeText {
    font-size: 28px;
    font-family: 'CircularBook';
}

.largeTextBold {
    font-size: 28px;
    font-family: 'CircularBold';
}

.smallText {
    font-size: 18px;
    font-family: 'CircularBook';
}

.smallTextBold {
    font-size: 18px;
    font-family: 'CircularBold';
}

.legal {
    font-size: 14px;
    font-family: 'CircularBook';
}

.number {
    font-size: 21px;
    font-family: 'CircularBlack';
}

.sweepstakeNumber {
    font-size: 50px;
    font-family: 'CircularBlack';
}

/* Text Colors */

.greenText {
    color: #00D767;
}

.darkGreenText {
    color: #00933E;
}

.lightGreenText {
    color: #00E58A;
}

.purpleText {
    color: #7B49FB;
}

.lightPurpleText {
    color: #F8F2FD;
}

.yellowText {
    color: #FFD51E;
}

.redText {
    color: #FF4B5F;
}

.lightRedText {
    color: #FFCACB;
}

.backgroundLightGreyText {
    color: #F9FCFF;
}

.backgroundGreyText {
    color: #F1F6FD;
}

.borderGreyText {
    color: #DFDFE2;
}

.textGreyText {
    color: #A8B6C7;
}

.blackText {
    color: #1B143D;
}

/* Background Colors */

.greenBackground {
    background-color: #00D767;
}

.darkGreenBackground {
    background-color: #00933E;
}

.lightGreenBackground {
    background-color: #00E58A;
}

.purpleBackground {
    background-color: #7B49FB;
}

.lightPurpleBackground {
    background-color: #F8F2FD;
}

.yellowBackground {
    background-color: #FFD51E;
}

.redBackground {
    background-color: #FF4B5F;
}

.lightRedBackground {
    background-color: #FFCACB;
}

.backgroundLightGreyBackground {
    background-color: #e9eff9;
}

.backgroundGreyBackground {
    background-color: #F1F6FD;
}

.borderGreyBackground {
    background-color: #DFDFE2;
}

.textGreyBackground {
    background-color: #A8B6C7;
}

.blackBackground {
    background-color: #1B143D;
}

.whiteBackground {
    background-color: white;
}

/* Drop Shadow */

.dropShadowNormal {
    box-shadow: 0px 2px 3px rgba(25, 35, 62, 0.11);
}

/* Corner Radius */

.cornerRadiusNormal {
    border-radius: 12px;
}

.cornerRadiusSmall {
    border-radius: 2px;
}

.cornerRadiusMedium {
    border-radius: 6px;
}

/* Margins */

.normalAll {
    margin: 40px;
}

.normalLeftRight {
    margin: 0px 40px;
}

.normalLeft {
    margin-left: 40px;
}

.normalRight {
    margin-right: 40px;
}

.normalBottom {
    margin-bottom: 40px;
}

/* Clickable */

.clickable {
    cursor: auto;
}

.clickable:hover {
    cursor: pointer;
}

@font-face {
    font-family: 'CircularBlack';
    src: url('../../Fonts//CircularStd-Black.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'CircularBook';
    src: url('../../Fonts/CircularStd-Book.otf') format('truetype');
    font-weight: regular;
    font-style: regular;
}

@font-face {
    font-family: 'CircularBold';
    src: url('../../Fonts/CircularStd-Bold.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

img {
    user-select: none;
    pointer-events: none;
}