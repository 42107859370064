/* Mobile optimized*/

@media only screen and (max-width: 500px) {
    #root {
        width: 100%;
    }
    .pricingWebTotal {
        display: none;
    }
    .pricingMobileTotal {
        flex-direction: column;
        flex: 1;
        text-align: center;
        margin-bottom: 30px;
    }
    .pricingTotalContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .calculatorTotalHeader {
        color: #c9c9c9;
        font-size: 18px;
        font-family: 'ColfoxBold';
        margin: 0px;
    }
    .sleekSubheaderFont2 {
        font-family: 'ColfoxBold';
        color: rgb(255, 255, 255);
        font-size: 22px;
        text-align: center;
        margin: 0px;
    }
    .calculatorTotal {
        font-size: 48px;
        font-family: 'ColfoxBold';
        margin: 0px;
    }
    .heroCTAContainer {
        margin: 30px;
    }
    .heroGif {
        flex: 1;
        margin: auto;
        padding: 30px;
        margin-top: -130px;
        width: 100%;
        /* transform: rotate3d(.5, -.866, 0, 15deg) rotateZ(1deg); */
        /* box-shadow: 27.1px 62.5px 125px -25px rgba(50, 50, 93, .5), 16.2px 37.5px 75px -37.5px rgba(0, 0, 0, .6); */
    }
    .sectionPadding {
        padding: 20px;
        padding-bottom: 40px;
        padding-top: 40px;
    }
    .primaryButton {
        color: white;
        background: #000000;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 6px;
        padding-bottom: 6px;
        border: none;
        border-radius: 8px;
        margin: auto;
        margin-bottom: 15px;
    }
    .sectionTitle {
        color: black;
        font-size: 35px;
        font-family: 'ColfoxBold';
        text-align: center;
        margin: auto;
        display: block;
        letter-spacing: -1px;
        /* width: 50%; */
    }
    .subSectionTitle {
        color: black;
        font-size: 16px;
        font-family: 'InterLight';
        margin: auto;
    }
    .howItWorksTitle {
        color: black;
        font-size: 16px;
        font-family: 'ColfoxBold';
        margin: auto;
    }
    /* Hero section */
    .heroSection {
        flex-direction: column;
        /* margin: 20px; */
        /* margin-top: 100px; */
        /* width: 100%; */
        padding: 20px;
    }
    .heroCTA {
        display: none;
    }
    .heroTitle {
        font-size: 32px;
        font-family: 'ColfoxMedium';
        text-align: center;
        letter-spacing: -1px;
        color: white;
    }
    .heroSubtitle {
        font-size: 16px;
        font-family: 'InterLight';
        text-align: center;
        /* color: white; */
    }
    .heroSubtitleBold {
        font-size: 20px;
        font-family: 'InterMedium';
    }
    .heroWrapper {
        /* overflow: hidden; */
        background-image: linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7)), url('//images.unsplash.com/photo-1501386761578-eac5c94b800a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80');
        height: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        padding-bottom: 130px;
    }
    .heroImage {
        margin: auto;
        flex: 1;
        width: '100%';
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .heroColumn {
        flex: 1;
        margin-bottom: 15px;
    }
    .heroCTA {
        margin: auto;
        text-align: center;
        /* width: 200px; */
        height: 50px;
        display: flex;
        line-height: 50px;
        margin-top: 50px;
    }
    .heroCardText {
        font-size: 25px;
        font-family: 'ColfoxBold';
        color: #e8e8e8;
        overflow: hidden;
        margin: 5px;
        background: linear-gradient(#e8e8e8 90%, rgb(255, 255, 255));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .heroCardSubText {
        font-size: 15px;
        font-family: 'ColfoxBold';
        color: rgb(99, 99, 99);
        margin-bottom: 5px;
    }
    .heroBlue {
        color: #0072ff;
        background: linear-gradient(#0072ff 90%, #0072ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .heroWave {
        position: absolute;
        width: unset;
        height: 800px;
        bottom: 0;
    }
    .heroBottomText {
        font-size: 16px;
        font-family: 'InterRegular';
        color: white;
        text-align: center;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .heroBottomTextBold {
        font-size: 16px;
        font-family: 'ColfoxBold';
        color: white;
        text-align: center;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .heroOutsideCard {
        display: none;
    }
    /* End Hero section */
    /* Mobile first section */
    .mobileImage {
        margin: auto;
        width: 100%;
        margin-top: 50px;
    }
    .mobileText {
        /* width: 70%; */
        margin: auto;
        display: block;
        margin-top: 30px;
    }
    .mobileCTA {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 50px;
    }
    .ratingsCSS {
        margin: auto;
        text-align: center;
        flex: 1;
    }
    .appstoreCSS {
        margin: auto;
        text-align: center;
        flex: 1;
    }
    /* End Mobile first section */
    /* How it works section */
    .howItWorksSteps {
        height: inherit;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 40px;
        align-items: baseline;
    }
    .howItWorksNumberText {
        margin-bottom: '10px';
    }
    /* End How it works section */
    /* Use Cases */
    .useCaseItem {
        flex-direction: column;
        margin-top: 30px;
    }
    .useCasesHeaderText {
        font-size: 24px;
        font-family: 'ColfoxBold';
        color: white;
    }
    .useCasesContextText {
        font-size: 18px;
        font-family: 'ColfoxBold';
        color: white;
    }
    .useCaseItemText {
        display: flex;
        flex: 1;
        flex-direction: row;
        /* margin-right: 10px;
        margin-left: 10px; */
    }
    .useCaseItemButton {
        flex: 1;
        margin: auto;
        margin-right: 0px;
        margin-left: 0px;
    }
    /* End Use Cases */
    /* Price Calculator */
    .priceCalculatorContainer {
        height: inherit;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 10px;
        border-radius: 25px !important;
        background-color: white;
        z-index: 1;
        padding: 20px;
    }
    /* Price Calculator */
    .notConvincedImage {
        visibility: hidden;
    }
    .qualitySection {
        max-width: 1200px;
        background-color: #ffffff;
        height: 70%;
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: #f9f9f9;
    }
    .qualityReasons {
        display: flex;
        flex-direction: column;
    }
    .qualitySectionheader {
        color: black;
        font-size: 18px;
        font-family: 'ColfoxBold';
    }
    .sectionSubtitle {
        font-size: 14px;
        font-family: 'InterLight';
    }
    .FAQFont {
        font-size: 18px;
        font-family: 'ColfoxBold';
        color: black;
    }
    .heroChatTick {
        margin-left: 150px;
    }
    .heroArrow {
        display: none;
        flex-direction: column;
        position: relative;
        margin-top: 80px;
    }
    .quote {
        color: #0072ff;
        font-size: 30px;
        font-family: 'ColfoxBold';
        text-align: center;
        margin: auto;
        display: block;
        height: 60px;
    }
    .ratingSocialProof {
        display: none;
    }
    .ratingFont {
        font-size: 26px;
        font-family: 'ColfoxBold';
        color: #ffd416;
        position: relative;
        display: inline-block;
        z-index: 10;
        width: 100%;
        text-align: center;
    }
    .heroContainer {
        padding-top: 0px;
        flex: 1;
        display: flex;
        flex-direction: column-reverse;
    }
    .bigger {
        transform: scale(1.15);
        height: 250px;
    }
    .featureSetUltimate {
        padding: 15px;
        padding-top: 40px;
    }
    .featureSetItems {
        display: flex;
        flex-direction: column;
    }
    .featureSetContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 30px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.15);
    }
    .featureSetIcon {
        padding-top: 20px;
    }
    .featureSetTitle {
        font-size: 20px;
        font-family: 'ColfoxBold';
        text-align: center;
        padding-top: 20px;
    }
    .featureSetSubtitle {
        font-size: 15px;
        font-family: 'InterLight';
        text-align: center;
        padding: 20px;
        padding-top: 10px;
    }
    .heroUltimate {
        padding-bottom: 120px;
        position: relative;
        background: #11C15E;
    }
    .touchOfModernTitle {
        flex: 1;
        margin-bottom: 15px;
        font-size: 32px;
        font-family: 'ColfoxBold';
        text-align: center;
        letter-spacing: -1px;
    }
    .touchOfModernTitleNotConvinced {
        font-size: 32px;
        font-family: 'ColfoxBold';
        text-align: center;
        letter-spacing: -1px;
        flex: 1;
        display: flex;
        flex-direction: column;
        /* color: white; */
        margin-top: 0px;
        margin-bottom: 5px;
    }
    .touchOfModernSubTitleNotConvinced {
        /* color: white; */
        text-align: center;
        font-size: 16px;
        font-family: 'InterLight';
        margin: auto;
    }
    .hideOnMobile {
        display: none;
    }
    .itAllStartsContainer {
        background-color: #f9f9f966;
        box-shadow: inset 0px 11px 8px -10px #ccc6, inset 0px -11px 8px -10px #ccc6;
    }
    .instructionStepContainer {
        display: flex;
        flex: 2;
        flex-direction: column;
        margin-bottom: 40px;
    }
    .notConvincedPadding {
        padding-top: 100px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .useCaseStepMeta {
        display: flex;
        flex-direction: column;
        margin: 30px;
        flex: 1;
    }
    .useCaseStepCardContainer {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .useCasecarousel__wrapper {
        /* border-radius: 20px; */
        /* margin: 2rem auto; */
        overflow: hidden;
        width: 100%;
        height: 100%;
        box-shadow: 0px 3px 8px 0px rgb(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
    }
    .useCaseCarouselWrapper {
        height: 300px;
    }
    #mobileFirstContainer {
        padding: 30px;
    }
    .mobileFirstTitleHeader {
        flex: 1;
        margin-bottom: 15px;
        font-size: 32px;
        font-family: 'ColfoxBold';
        text-align: center;
        letter-spacing: -1px;
        /* white-space: nowrap; */
    }
    .mobileFirstSubtitle {
        color: white;
        font-size: 14px;
        font-family: 'InterLight';
        text-align: center;
        margin: 20px;
    }
    .stars {
        margin: auto;
    }
    .mobileFirstReasonTitle {
        color: black;
        font-size: 20px;
        font-family: 'ColfoxBold';
        margin: 0px;
    }
    .mobileFirstReasonSubtitle {
        color: black;
        font-size: 16px;
        font-family: 'InterLight';
    }
    .featureSetNumbers {
        /* background-color: #0072FF; */
        height: inherit;
        display: flex;
        flex-direction: column;
        margin: 30px;
        border-radius: 20px;
        box-shadow: 0px 3px 8px 0px rgb(0, 0, 0, 0.12);
        background-image: radial-gradient( circle at top right, #0099ff, #0072ff);
    }
    .socialProofQuote {
        color: white;
        font-size: 18px;
        font-family: 'ColfoxMedium';
        flex: 4;
        margin: 30px;
    }
    .socialProofContainer {
        display: flex;
        flex-direction: column;
        flex: 2;
        justify-content: flex-end;
        margin: 20px;
        /* margin-right: 40px; */
    }
    .featureSetNumbersSubtitle {
        font-size: 20px;
        font-family: 'InterLight';
        text-align: center;
        margin: 0px;
    }
    .socialProofPhoto {
        width: 100px;
        height: 100px;
        border-radius: 60px;
        margin: auto;
        border-style: solid;
        border-width: 5px;
        border-color: white;
    }
    .socialProofPhotoContainer {
        margin: auto;
        margin-bottom: 20px;
    }
    .sleekHeroCopyContainer {
        /* margin-right: 50px;
        margin-left: 20px */
    }
    .sleekHeaderFont {
        font-size: 32px;
        font-family: 'ColfoxBold';
        text-align: center;
        letter-spacing: -1px;
        color: white;
    }
    .sleekHeroImage {
        display: none;
    }
    .sleekSubheaderFont {
        font-size: 16px;
        font-family: 'InterRegular';
        color: #ffffff;
        text-align: center;
    }
    .sleekSubheaderBoldFont {
        font-size: 16px;
        font-family: 'ColfoxBold';
        color: #e1fdd4;
    }
    .sleekReportsQuestion {
        font-size: 25px;
        font-family: 'ColfoxBold';
        letter-spacing: -1px;
        color: white;
    }
    .sleekReportsInfo {
        font-size: 16px;
        font-family: 'ColfoxMedium';
        color: white;
        margin-bottom: 5px;
    }
    .sleekReportsContainer {
        display: flex;
        flex-direction: column;
    }
    .sleekReportBox {
        flex: 1;
        flex-direction: column;
        display: flex;
        flex-grow: 1;
        padding: 15px;
        /* background: green; */
        /* height: 250px; */
        box-shadow: 0px 2px 3px 2px rgb(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-top: 40px;
    }
    .sleekReportsButton {
        display: flex;
        flex-direction: row;
        flex: 1;
        padding: 0px;
        z-index: 100;
        display: flex;
        margin-bottom: 0px;
        margin-top: 15px;
        border: none;
        border-radius: 4px;
        height: 60px;
        line-height: 60px;
        margin-top: 15px;
        cursor: pointer;
    }
    .sleekQuoteContainer {
        background-color: #f3e9fd;
        height: inherit;
        display: flex;
        flex-direction: column;
        /* margin: 30px; */
        margin-top: 60px;
        border-radius: 4px;
        /* box-shadow: 0px 3px 8px 0px rgb(0, 0, 0, 0.12); */
    }
    .sleekSocialProofQuote {
        color: #9f4ff1;
        font-size: 24px;
        font-family: 'ColfoxMedium';
        flex: 4;
        margin: 30px;
        margin-top: 120px;
    }
    .sleekHowItWorksStepContainer {
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    .sleekHowItWorksText {
        display: flex;
        flex-direction: column;
        /* flex: 1; */
        /* max-width: 500px; */
        /* margin: auto; */
    }
    .sleekHowItWorksImage {
        width: 75%;
        display: flex;
        margin: auto;
    }
    .sleekHowItWorksTextContainer {
        margin: 20px;
        flex: 1;
        display: flex;
    }
    .sleekHowItWorksImageContainer {
        flex: 1;
        margin: 10px;
    }
    .sleekReportsEmailCaptureContainer {
        /* width: 600px; */
        margin: auto;
        margin-top: 30px;
        text-align: center;
    }
    .sleekReportsEmailCaptureHeader {
        margin-top: 120px;
        text-align: center;
        font-size: 19px;
        font-family: 'ColfoxBold';
        letter-spacing: -1px;
        margin-bottom: 5px;
        color: #0072ff;
    }
    .sleekReportsEmailCaptureHeader2 {
        margin-top: -10px;
        text-align: center;
        font-size: 34px;
        font-family: 'ColfoxBold';
        letter-spacing: -1px;
        margin-bottom: 5px;
        color: #0072ff;
    }
    .socailProofImage {
        margin: auto;
        width: 100%;
        /* max-width: 500px */
    }
    .socialProofContainer {
        margin: 15px;
    }
    .sleekMobileFirstContainer {
        display: flex;
        flex-direction: column-reverse;
        /* max-width: 1200px; */
        margin: auto;
    }
    .sleekMobileFirstTitleHeader {
        font-size: 35px;
        font-family: 'ColfoxBold';
        letter-spacing: -1px;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        color: white;
        text-align: center;
        margin-bottom: 10px;
        line-height: 1;
    }
    .sleekSocialProofTextContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 5;
        margin-top: 100px;
        margin-bottom: 20px;
        /* padding-left: 100px; */
        align-self: center;
    }
}

/* Web */

.sleekButtonColors0 {
    background: #ecf5ff;
    color: #0072ff;
}

.sleekButtonColors1 {
    background: #f3e9fd;
    color: #9f4ff1;
}

.sleekButtonColors2 {
    background: #ffe8ef;
    color: #fc014a;
}

@media only screen and (min-width: 501px) {
    .logoSocial {
        font-family: 'ColfoxBold';
        color: #0072ff;
        font-size: 32px;
    }
    .logoLoop {
        font-family: 'ColfoxLight';
        color: #0072ff;
        font-size: 32px;
    }
    .sleekSubheaderFont2 {
        font-family: 'ColfoxBold';
        color: rgb(255, 255, 255);
        font-size: 22px;
    }
    .sleekSocialProofTextContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 5;
        margin-top: 100px;
        margin-bottom: 100px;
        padding-left: 100px;
        align-self: center;
    }
    .sleekMobileFirstContainer {
        display: flex;
        flex-direction: row;
        max-width: 1200px;
        margin: auto;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .socialProofContainer {
        /* margin: 15px; */
    }
    .socailProofImage {
        margin: auto;
        width: 50%;
        max-width: 500px;
    }
    .sleekMobileFirstTitleHeader {
        font-size: 55px;
        font-family: 'ColfoxBold';
        /* text-align: center; */
        letter-spacing: -1px;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        color: white;
        text-align: left;
        margin-bottom: 10px;
        line-height: 1;
        /* white-space: nowrap; */
    }
    .sleekReportsEmailCaptureContainer {
        width: 600px;
        margin: auto;
        margin-top: 30px;
    }
    .sleekReportsEmailCaptureHeader {
        margin-top: 120px;
        text-align: center;
        font-size: 34px;
        font-family: 'ColfoxBold';
        letter-spacing: -1px;
        margin-bottom: 5px;
        color: #0072ff;
    }
    .sleekReportsEmailCaptureHeader2 {
        margin-top: -10px;
        text-align: center;
        font-size: 34px;
        font-family: 'ColfoxBold';
        letter-spacing: -1px;
        margin-bottom: 5px;
        color: #0072ff;
    }
    .sleekHowItWorksImageContainer {
        flex: 1;
        margin: 50px;
    }
    .sleekHowItWorksTextContainer {
        margin: 50px;
        flex: 1;
        display: flex;
    }
    .sleekHowItWorksImage {
        max-height: 300px;
        display: flex;
    }
    .sleekHeroImage {
        display: flex;
        flex: 6;
    }
    .sleekNotConvincedCTA {
        /* margin: auto; */
        margin-top: 40px;
        height: 60px;
        display: flex;
    }
    .sleekSocialProofQuote {
        color: #9f4ff1;
        font-size: 28px;
        font-family: 'ColfoxMedium';
        flex: 4;
        margin: 80px;
    }
    .sleekQuoteContainer {
        background-color: #f3e9fd;
        height: inherit;
        display: flex;
        flex-direction: row;
        margin: 30px;
        margin-top: 100px;
        border-radius: 4px;
        /* box-shadow: 0px 3px 8px 0px rgb(0, 0, 0, 0.12); */
    }
    .sleekHowItWorksStepContainer {
        display: flex;
        flex-direction: row;
        margin: auto;
    }
    .sleekHowItWorksSubtitle {
        font-size: 24px;
        font-family: 'InterLight';
    }
    .sleekHowItWorksStepContainerReversed {
        display: flex;
        flex-direction: row-reverse;
        margin: auto;
        margin-top: 70px;
        margin-bottom: 70px;
    }
    .sleekHowItWorksText {
        display: flex;
        flex-direction: column;
        /* flex: 1; */
        max-width: 500px;
        margin: auto;
    }
    .sleekHowItWorksTitle {
        font-size: 34px;
        font-family: 'ColfoxBold';
        letter-spacing: -1px;
        margin-bottom: 5px;
    }
    .sleekReportsQuestion {
        font-size: 25px;
        font-family: 'ColfoxBold';
        letter-spacing: -1px;
        color: white;
    }
    .sleekReportsInfo {
        font-size: 16px;
        font-family: 'ColfoxMedium';
        color: white;
        margin-bottom: 5px;
    }
    .sleekReportsContainer {
        display: flex;
        flex-direction: row;
    }
    .sleekReportsButton {
        display: flex;
        flex-direction: row;
        flex: 1;
        padding: 0px;
        z-index: 100;
        display: flex;
        margin-bottom: 0px;
        margin-top: 15px;
        border: none;
        border-radius: 4px;
        height: 60px;
        line-height: 60px;
        margin-top: 15px;
        cursor: pointer;
    }
    .sleekReportBox {
        flex: 1;
        flex-direction: column;
        display: flex;
        flex-grow: 1;
        margin: 30px;
        padding: 15px;
        /* background: green; */
        /* height: 250px; */
        box-shadow: 0px 2px 3px 2px rgb(0, 0, 0, 0.15);
        border-radius: 8px;
    }
    .sleekHeaderFont {
        font-size: 60px;
        font-family: 'ColfoxBold';
        letter-spacing: -1px;
        line-height: 1.1;
        color: white;
        margin-bottom: 20px;
    }
    .sleekSubheaderFont {
        font-size: 24px;
        font-family: 'InterMedium';
        color: #e1fdd4;
    }
    .sleekSubheaderBoldFont {
        font-size: 24px;
        font-family: 'ColfoxBold';
        color: #e1fdd4;
    }
    .sleekHeroCopyContainer {
        margin-right: 50px;
        margin-left: 20px;
        text-align: left;
    }
    .sleekHeroCTA {
        /* margin: auto; */
        margin-bottom: 10px;
        height: 50px;
        display: flex;
    }
    .sleekHeroCTAButton {}
    .pricingWebTotal {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .pricingMobileTotal {
        display: none;
    }
    .pricingTotalContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .socialProofPhotoContainer {
        margin-left: auto;
    }
    .socialProofPhoto {
        width: 100px;
        height: 100px;
        border-radius: 60px;
        margin-left: auto;
        border-style: solid;
        border-width: 5px;
        border-color: white;
        margin-bottom: 10px;
    }
    .socialProofContainer {
        display: flex;
        flex-direction: column;
        flex: 2;
        justify-content: flex-end;
        margin: 20px;
        margin-right: 40px;
    }
    .socialProofQuote {
        color: white;
        font-size: 28px;
        font-family: 'ColfoxMedium';
        flex: 4;
        margin: 80px;
        /* margin-bottom: auto;
        margin-top: auto; */
    }
    .hideOnWeb {
        display: none;
    }
    .mobileFirstTitleHeader {
        font-size: 55px;
        font-family: 'ColfoxBold';
        /* text-align: center; */
        letter-spacing: -1px;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        color: black;
        text-align: left;
        margin-bottom: 10px;
        line-height: 1;
        white-space: nowrap;
    }
    .useCaseCarouselWrapper {
        flex: 2;
    }
    .useCasecarousel__wrapper {
        border-radius: 10px;
        overflow: hidden;
        width: 70%;
        box-shadow: 0px 2px 10px 12px rgb(0, 0, 0, 0.16);
        display: flex;
        flex-direction: column;
        margin: 30px;
    }
    .useCaseStepCardContainer {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .useCaseStepMeta {
        display: flex;
        flex-direction: column;
        margin: 30px;
        flex: 1;
    }
    .notConvincedPadding {
        width: 70%;
        margin: auto;
        margin-top: 100px;
    }
    .instructionStepContainer {
        display: flex;
        flex: 2;
        flex-direction: column;
        margin-right: 20px;
    }
    .itAllStartsContainer {
        background-color: #f9f9f966;
        box-shadow: inset 0px 11px 8px -10px #ccc6, inset 0px -11px 8px -10px #ccc6;
    }
    .touchOfModernTitleNotConvinced {
        font-size: 55px;
        font-family: 'ColfoxBold';
        text-align: left;
        letter-spacing: -1px;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        /* color: white; */
        margin-top: 0px;
    }
    .touchOfModernSubTitleNotConvinced {
        /* color: white; */
        font-size: 18px;
        font-family: 'InterLight';
    }
    .touchOfModernTitle {
        font-size: 55px;
        font-family: 'ColfoxBold';
        text-align: center;
        letter-spacing: -1px;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        color: black;
    }
    .featureSetItems {
        display: flex;
        flex-direction: row;
        max-width: 1200px;
        margin: auto;
    }
    .useCaseList {
        font-size: 20px;
        font-family: 'ColfoxBold';
        text-align: center;
        color: #dddddd;
    }
    .featureSetNumbers {
        /* background-color: #0072FF; */
        height: inherit;
        display: flex;
        flex-direction: row;
        margin: 30px;
        border-radius: 20px;
        box-shadow: 0px 3px 8px 0px rgb(0, 0, 0, 0.12);
        background-image: radial-gradient( circle at top right, #0099ff, #0072ff);
    }
    .featureSetNumbersSubtitle {
        font-size: 20px;
        font-family: 'InterLight';
        text-align: right;
        margin: 0px;
        /* padding-bottom: 30px; */
    }
    .featureSetHeader {
        color: black;
        font-size: 45px;
        font-family: 'ColfoxBold';
        /* text-align: center; */
        margin: auto;
        display: block;
        letter-spacing: -1px;
    }
    .featureSetIcon {
        padding-top: 20px;
    }
    .featureSetTitle {
        font-size: 20px;
        font-family: 'ColfoxBold';
        text-align: center;
        padding-top: 20px;
    }
    .featureSetSubtitle {
        font-size: 15px;
        font-family: 'InterLight';
        text-align: center;
        padding: 20px;
        padding-top: 10px;
    }
    .featureSetContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 30px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 3px 8px 2px rgb(0, 0, 0, 0.15);
    }
    .featureSetUltimate {
        padding-top: 180px;
        padding-bottom: 30px;
    }
    .bigger {
        transform: scale(1.15);
        height: 350px;
    }
    .heroBubble {
        display: flex;
        flex-direction: row;
        margin-top: 100px;
        height: 350px;
    }
    .heroContainer {
        padding: 60px;
        /* padding-top: 130px; */
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .ratingFont {
        font-size: 26px;
        font-family: 'ColfoxBold';
        color: white;
        position: relative;
        display: inline-block;
        z-index: 10;
    }
    .ratingSocialProof {
        text-align: right;
        font-size: 26px;
        font-family: 'InterMedium';
    }
    .quote {
        color: #0072ff;
        font-size: 35px;
        font-family: 'ColfoxBold';
        text-align: center;
        margin: auto;
        display: block;
        /* width: 50%; */
    }
    .heroArrow {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 50px;
    }
    .heroChatTick {
        margin-left: -30px;
    }
    .FAQFont {
        font-size: 26px;
        font-family: 'ColfoxBold';
        color: black;
        /* position: relative;
        display: inline-block;
        z-index: 10; */
    }
    .qualityReasons {
        display: flex;
        flex-direction: row;
    }
    .qualitySectionheader {
        color: black;
        font-size: 18px;
        font-family: 'ColfoxBold';
    }
    .qualitySection {
        max-width: 1200px;
        background-color: #ffffff;
        height: 70%;
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: #f9f9f9;
    }
    /* Price Calculator */
    .priceCalculatorContainer {
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: white;
        display: flex;
        flex: 2;
        border-radius: 25px !important;
        padding: 20px;
        z-index: 1;
    }
    .calculatorStatsSection {
        color: #c9c9c9;
        font-size: 14px;
        font-family: 'ColfoxBold';
    }
    .calculatorTotalHeader {
        color: #c9c9c9;
        font-size: 18px;
        font-family: 'ColfoxBold';
        margin: 0px;
    }
    .calculatorTotal {
        font-size: 58px;
        font-family: 'ColfoxBold';
        margin: 0px;
    }
    .calculatorStats {
        color: black;
        font-size: 18px;
        font-family: 'ColfoxBold';
    }
    .interactivity {
        flex: 2;
        margin: auto;
    }
    .pricingTotal {
        flex: 1;
        /* padding-left: 60px; */
        margin-top: 40px;
        /* margin: auto; */
        margin-bottom: 40px;
        z-index: 3;
        height: 200px;
    }
    /* Price Calculator */
    /* Use Cases */
    /* .useCaseZoom:focus { */
    /* .useCaseItem:hover .useCaseZoom {
        transform: scale(1.2);
        transition: all .5s;

    } */
    .useCaseItem {
        display: flex;
        flex-direction: row;
        margin-top: 100px;
        min-height: 400px;
    }
    .useCaseItemReverse {
        display: flex;
        flex-direction: row-reverse;
    }
    .useCaseStepContainer {
        height: inherit;
        display: flex;
        flex-direction: column;
        /* margin: auto; */
    }
    .useCasesHeaderText {
        font-size: 40px;
        /* font-family: 'InterMedium'; */
        font-family: 'ColfoxBold';
        color: white;
    }
    .useCasesContextText {
        font-size: 25px;
        font-family: 'ColfoxBold';
        color: white;
    }
    .useCaseItemText {
        /* display: flex;
        flex: 1;
        flex-direction: column;
        margin-right: 40px;
        margin-left: 40px; */
        color: #c2c2c2;
    }
    .useCaseItemButton {
        flex: 1;
        margin: auto;
        margin-right: 40px;
        margin-left: 40px;
    }
    /* End Use Cases */
    /* How it works section */
    .howItWorksSteps {
        height: inherit;
        display: flex;
        flex-direction: column;
        margin: auto;
        align-items: baseline;
        width: 100%;
    }
    .howItWorksNumberText {
        margin-bottom: '20px';
    }
    /* End How it works section */
    /* Mobile first section */
    .mobileImage {
        margin: auto;
        /* width: 70%; */
        height: 100%;
        position: absolute;
        right: 0px;
        /* margin-top: 50px; */
    }
    .mobileText {
        width: 70%;
        margin: auto;
        display: block;
        margin-top: 30px;
    }
    .mobileCTA {
        /* display: flex;
        flex-direction: row; */
        /* flex: 1; */
        /* margin: auto; */
        margin-top: 80px;
        flex-direction: row;
        align-items: center;
        display: flex;
    }
    .mobileFirstTitle {
        color: black;
        font-size: 45px;
        font-family: 'ColfoxBold';
        margin: 0px;
        margin-top: 60px;
    }
    .mobileFirstSubtitle {
        color: white;
        font-size: 20px;
        font-family: 'InterLight';
    }
    #mobileFirstContainer:before {
        box-shadow: inset 0 6px 6px -6px rgba(0, 0, 0, 0.27);
    }
    #mobileFirstContainer:after {
        box-shadow: inset 0 -6px 6px -6px rgba(0, 0, 0, 0.27);
    }
    .mobileFirstReasonTitle {
        color: black;
        font-size: 20px;
        font-family: 'ColfoxBold';
        margin: 0px;
    }
    .mobileFirstReasonSubtitle {
        color: black;
        font-size: 16px;
        font-family: 'InterLight';
    }
    .ratingsAppStore {
        margin-bottom: 100px;
    }
    .mobileFirstTableHeader {
        color: white;
        font-size: 18px;
        font-family: 'InterMedium';
        margin: auto;
    }
    .mobileFirstTable {
        width: 75%;
        margin: auto;
    }
    .ratingsCSS {
        margin-right: 30px;
        /* text-align: right; */
        flex: 1;
    }
    .appstoreCSS {
        margin-left: 100px;
        text-align: left;
        flex: 1;
        height: 50px;
    }
    /* End Mobile first section */
    /* Hero section */
    .heroUltimate {
        /* padding-bottom: 220px; */
        /* height: 90vh; */
        height: 900px;
        display: flex;
        position: relative;
        background: #11C15E;
    }
    .heroCTAContainer {
        /* margin: auto; */
        margin-bottom: 10px;
        height: 50px;
        display: flex;
    }
    .heroCTA {
        margin: auto;
        text-align: center;
        /* width: 200px; */
        height: 50px;
        display: flex;
        line-height: 50px;
    }
    .heroWrapper {
        background-image: linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7)), url('//championsridedays.com.au/wp-content/uploads/2017/03/concert-smoke-crowd-people-concert-music-youth-club-photos-crowd-cheering-the-mood-the-smoke-tools-136417-2560x1440.jpg');
        /* background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7)), url('https://ak0.picdn.net/shutterstock/videos/6907540/thumb/1.jpg'); */
        height: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        padding-bottom: 300px;
    }
    .heroColumn {
        flex: 1;
        /* margin: auto; */
        margin-bottom: 25px;
    }
    .heroImage {
        /* margin-left: '50px'; */
        flex: 1;
        overflow-x: hidden;
        overflow-y: hidden;
        width: 100%;
        max-width: 1000px;
        margin: auto;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .heroImage::-webkit-scrollbar {
        width: 0 !important;
    }
    .heroSection {
        display: flex;
        flex-direction: column;
        margin: auto;
        /* margin-top: 100px; */
        margin-top: 150px;
        max-width: 1200px;
        width: 100%;
        height: 100%;
    }
    .heroCardText {
        font-size: 25px;
        font-family: 'ColfoxBold';
        color: #e8e8e8;
        overflow: hidden;
        margin: 10px;
        background: linear-gradient(#e8e8e8 90%, rgb(255, 255, 255));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .heroCardSubText {
        font-size: 15px;
        font-family: 'ColfoxBold';
        color: rgb(99, 99, 99);
        margin-bottom: 5px;
    }
    .heroBlue {
        color: #0072ff;
        background: linear-gradient(#0072ff 90%, #0072ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .heroWave {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
    .heroBottomTitleText {
        font-size: 20px;
        font-family: 'ColfoxBold';
        color: #888888;
        text-align: center;
        width: 50%;
        margin: auto;
        margin-top: 20px;
    }
    .heroBottomText {
        font-size: 20px;
        font-family: 'InterLight';
        color: white;
        text-align: center;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .heroBottomTextBold {
        font-size: 20px;
        font-family: 'ColfoxBold';
        color: white;
        text-align: center;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .heroGif {
        flex: 1;
        margin: auto;
        margin-top: -150px;
        max-width: 800px;
        /* transform: rotate3d(.5, -.866, 0, 15deg) rotateZ(1deg); */
        box-shadow: 27.1px 62.5px 125px -25px rgba(50, 50, 93, 0.5), 16.2px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
    }
    .heroCTA {
        display: flex;
        flex-direction: row;
    }
    .heroTitle {
        font-size: 55px;
        font-family: 'ColfoxBold';
        text-align: center;
        letter-spacing: -1px;
        color: white;
    }
    .heroSubtitle {
        font-size: 26px;
        font-family: 'InterLight';
        text-align: center;
        width: 50%;
        color: gray;
    }
    .heroSubtitleBold {
        font-size: 18px;
        font-family: 'InterMedium';
        text-align: center;
    }
    /* Hero section */
    .primaryButton {
        color: white;
        background: #000000;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 6px;
        padding-bottom: 6px;
        border: none;
        border-radius: 8px;
    }
    .sectionPadding {
        padding: 60px;
    }
    .sectionInset {
        max-width: 1000px;
        margin: auto;
    }
    .sectionTitle {
        color: black;
        font-size: 45px;
        font-family: 'ColfoxBold';
        text-align: center;
        margin: auto;
        display: block;
        margin-top: 100px;
        letter-spacing: -1px;
        /* width: 50%; */
    }
    .subSectionTitle {
        color: black;
        font-size: 24px;
        font-family: 'InterLight';
        margin: auto;
    }
    .howItWorksTitle {
        color: black;
        font-size: 20px;
        font-family: 'ColfoxBold';
        margin: auto;
    }
    .sectionSubtitle {
        font-size: 18px;
        font-family: 'InterLight';
    }
}

/* Mobile optimized*/

@font-face {
    font-family: 'ColfoxBold';
    src: url('../../Fonts/colfaxBold.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'ColfoxLight';
    src: url('../../Fonts/colfaxLight.ttf') format('truetype');
    font-weight: light;
    font-style: light;
}

@font-face {
    font-family: 'ColfoxMedium';
    src: url('../../Fonts/colfaxMedium.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'InterLight';
    src: url('../../Fonts/Inter-Light-BETA.otf') format('opentype');
    font-weight: light;
    font-style: light;
}

@font-face {
    font-family: 'InterRegular';
    src: url('../../Fonts/Inter-Regular.otf') format('opentype');
    font-weight: regular;
    font-style: regular;
}

@font-face {
    font-family: 'InterMedium';
    src: url('../../Fonts/Inter-Medium.otf') format('opentype');
    font-weight: medium;
    font-style: medium;
}

.navHeader {
    font-size: 30px;
    font-family: 'ColfoxBold';
    color: black;
}

.navItem {
    font-size: 16px;
    font-family: 'ColfoxBold';
    text-decoration: none;
}

.section {
    margin: 60px;
}

.grow {
    transition: all 0.13s ease-in-out;
}

.grow:hover {
    transform: scale(1.05);
}

.slightGrow {
    transition: all 0.23s ease-in-out;
}

.slightGrow:hover {
    transform: scale(1.02);
}

.useCaseButton {
    color: white;
    font-size: 24px;
    font-family: 'ColfoxBold';
    margin: auto;
    text-align: center;
}

.sideMenu {
    color: black;
    font-size: 14px;
    font-family: 'ColfoxBold';
}

.calculatorSection {
    color: black;
    font-size: 16px;
    font-family: 'InterMedium';
}

.calculatorTotalSectionDiscount {
    color: rgb(238, 24, 24);
    font-size: 22px;
    font-family: 'ColfoxBold';
}

.calculatorTotalSection {
    color: black;
    font-size: 28px;
    font-family: 'ColfoxBold';
}

.modal-dialog2 {
    max-width: none !important;
    width: 90% !important;
    height: inherit;
    /* margin: 30px !important; */
}

.secondaryButton {
    color: #0072ff !important;
    border: none;
    padding-left: 14px !important;
    padding-right: 14px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    /* border: none; */
    border-radius: 8px !important;
    background-color: white;
}

.secondaryButton2 {
    color: #0072ff !important;
    border: none;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    /* border: none; */
    border-radius: 8px !important;
    background-color: white;
}

.primaryButton2 {
    color: white;
    background: #0072ff;
    padding-left: 14px !important;
    padding-right: 14px !important;
    padding-top: 6px;
    padding-bottom: 6px;
    border: none;
    border-radius: 8px;
}

.navigationButton {
    color: black;
    border: none;
    /* padding-left: 14px!important; */
    /* padding-right: 14px!important; */
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    /* border: none; */
    border-radius: 8px !important;
    /* background-color: white; */
}

.calculatorTotalSectionDiscountBox {
    color: white;
    background: rgb(238, 24, 24);
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: none;
    border-radius: 8px;
}

.dropShadow {
    /* box-shadow: 0px 2px 24px 6px rgb(0, 0, 0, 0.1); */
    box-shadow: 0px 3px 8px 0px rgb(0, 0, 0, 0.12);
}

.quoteCardDropShadow {
    /* box-shadow: 0px 2px 24px 6px rgb(0, 0, 0, 0.1); */
    box-shadow: 0px 2px 5px 0px rgb(0, 0, 0, 0.42);
}

.privacySubtitleBold {
    font-size: 17px;
    font-family: 'InterMedium';
}

.privacySectionSubtitle {
    font-size: 17px;
    font-family: 'InterLight';
}

.questionResultCardContent {
    font-size: 17px;
    font-family: 'InterRegular';
}

.questionResultCardAttribute {
    font-size: 14px;
    font-family: 'InterMedium';
}

.borderRadius {
    border-radius: 20px;
}

a[href], input[type='submit'], input[type='image'], label[for], select, button, .pointer {
    cursor: pointer;
}

.unselectable {
    /* For Opera and <= IE9, we need to add unselectable='on' attribute onto each element */
    /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
    -moz-user-select: none;
    /* These user-select properties are inheritable, used to prevent text selection */
    -webkit-user-select: none;
    -ms-user-select: none;
    /* From IE10 only */
    user-select: none;
    /* Not valid CSS yet, as of July 2012 */
    -webkit-user-drag: none;
    /* Prevents dragging of images/divs etc */
    user-drag: none;
}

.rawResponse {
    background-color: rgb(249, 249, 249);
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    width: 100%;
    box-shadow: 0px 2px 24px 6px rgb(0, 0, 0, 0.06);
}

.useCaserawResponse {
    background-color: #0072ff;
    /* border-radius: 8px; */
    padding: 20px;
    height: 100%;
    width: 100%;
    box-shadow: 0px 2px 10px 12px rgb(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    flex: 1;
}

.useCasequestionResultCardContent {
    font-size: 18px;
    font-family: 'ColfoxMedium';
    color: white;
    margin-top: 30px;
    text-indent: 40px;
}

.useCasequestionResultCardAttribute {
    font-size: 18px;
    font-family: 'InterMedium';
    color: rgba(255, 255, 255, 0.9);
    text-align: end;
}

.useCasecarousel__button:hover {
    background: white;
    transition: all 0.13s ease-in-out;
    /* transform: scale(1.05); */
}

.useCasecarousel__button {
    background: #d8dfe9;
    border: 0;
    color: #0072ff;
    cursor: pointer;
    font-size: 1rem;
    flex-grow: 1;
    outline: none;
    padding: 1rem;
    transition: all 0.1s ease-out;
}

.rawResponseContainer {
    height: 100%;
    width: 100%;
}

.blueWaveContainer {
    width: auto;
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 auto;
    overflow: hidden;
    z-index: 0;
}

.blueWaveImageContainer {
    height: 100%;
    position: relative;
}

.centerme {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.blueWaveSVG {
    z-index: -1;
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.blueWaveTextContainer {
    width: 240%;
    position: absolute;
    z-index: 10;
    text-align: center;
    transform: rotate(-15deg);
}

.blueWaveText {
    position: absolute;
    bottom: 0px;
    left: -500px;
    color: #ffffff0a;
    font-size: 75px;
    font-family: 'ColfoxBold';
    text-align: center;
}

.signInTitle {
    color: black;
    font-size: 30px;
    font-family: 'ColfoxBold';
    margin: auto;
}

html {
    scroll-behavior: smooth;
}