.text-field {
    width: 100%;
    padding: 8px 16px;
    border: 1px solid rgb(211, 224, 240);
    margin-bottom: 20px;
}

.text-field-error {
    width: 100%;
    padding: 8px 16px;
    border: 1px solid rgba(249, 24, 24, 0.947);
    margin-bottom: 20px;
}
::placeholder {
    color: #A8B6C7;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #A8B6C7;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: #A8B6C7;
  }