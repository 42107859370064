.sign-up-container {
    height: 100vh;
    background-color: yellow;
    display: flex;
    flex-direction: row;
}

.sign-up-left {
    display: flex;
    flex: 2;
    background-color: white;
    justify-content: center;
}

.sign-up-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 3;
    background: #DBFDE9;
}

.sign-up-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
}

.sign-up-welcome-label {
    text-align: center;
    margin-bottom: 50px;
}

.sign-up-gender-birthday {
    display: flex;
    flex-direction: row;
}

.sign-up-legal {
    margin: 0px auto;
    margin-bottom: 20px;
    width: 70%;
    text-align: center;
}

.sign-up-right-text-container {
    padding: 100px;
}

.sign-up-gender-picker {
    width: 100%;
}