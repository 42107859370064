.admin-sidebar-container {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: white;
}

.admin-sidebar-link-container {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: 10px;
}

.admin-sidebar-link {
    font-size: 18px;
    font-family: 'CircularBlack';
    color: rgb(24, 24, 24);
    margin-left: 15px;
}

.admin-sidebar-filler {
    flex: 1
}

.admin-sidebar-version {
    font-size: 12px;
    font-family: 'CircularBook';
    color: rgb(24, 24, 24);
    text-align: center;
}

@font-face {
    font-family: 'CircularBlack';
    src: url('../../../Fonts//CircularStd-Black.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'CircularBook';
    src: url('../../../Fonts/CircularStd-Book.otf') format('truetype');
    font-weight: regular;
    font-style: regular;
}

@font-face {
    font-family: 'CircularMedium';
    src: url('../../../Fonts/CircularStd-Medium.otf') format('truetype');
    font-weight: medium;
    font-style: medium;
}