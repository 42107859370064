@media only screen and (max-width: 500px) {
    .textContainer {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

@media only screen and (min-width: 501px) {
    .textContainer {
        width: 1000px;
        margin: auto;
        margin-top: 100px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

.container {
    display: flex;
    flex-direction: column;
}

.title {
    margin-top: 35px;
    text-align: center;
    font-family: 'CircularBlack';
    font-size: 40px;
}

.sectionTitle {
    margin-top: 55px;
    text-align: center;
    font-family: 'CircularBlack';
    font-size: 24px;
}

.subsectionTitle {
    margin: 0px;
    margin-top: 25px;
    font-family: 'CircularMedium';
    font-size: 22px;
}

.subsectionText {
    margin: 0px;
    margin-top: 15px;
    font-family: 'CircularBook';
    font-size: 18px;
    margin-bottom: 35px;
}

@font-face {
    font-family: 'CircularBlack';
    src: url('../../Fonts/CircularStd-Black.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'CircularBook';
    src: url('../../Fonts/CircularStd-Book.otf') format('truetype');
    font-weight: regular;
    font-style: regular;
}

@font-face {
    font-family: 'CircularMedium';
    src: url('../../Fonts/CircularStd-Medium.otf') format('truetype');
    font-weight: medium;
    font-style: medium;
}
