.lucid-inventory-progress {
    /* display: flex;
    flex: 1; */
    width: 100%;
}

.progress {
    /* height: 10px; */
}

.tables-monospace {
    font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
    font-size: 12px;
}

.tables-cell {
    margin: 0px;
    font-size: 14px !important;
    font-family: 'CircularBook';
    color: rgb(37, 37, 37);
    white-space: nowrap;
}

.tables-black-font {
    font-size: 13px;
    font-family: 'CircularBlack';
    color: rgb(24, 24, 24);
    margin-bottom: 0px;
    text-align: right;
}

.tables-red-font {
    font-size: 13px;
    font-family: 'CircularBlack';
    color: rgb(216, 0, 0);
    margin-bottom: 0px;
    text-align: right;
}

.tables-green-font {
    font-size: 13px;
    font-family: 'CircularBlack';
    color: rgb(34, 157, 34);
    margin-bottom: 0px;
    text-align: right;
}

.tables-light-red-font {
    font-size: 13px;
    font-family: 'CircularBlack';
    color: rgb(223, 174, 69);
    margin-bottom: 0px;
    text-align: right;
}

@font-face {
    font-family: 'CircularBlack';
    src: url('../../../Fonts//CircularStd-Black.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'CircularBook';
    src: url('../../../Fonts/CircularStd-Book.otf') format('truetype');
    font-weight: regular;
    font-style: regular;
}

@font-face {
    font-family: 'CircularMedium';
    src: url('../../../Fonts/CircularStd-Medium.otf') format('truetype');
    font-weight: medium;
    font-style: medium;
}