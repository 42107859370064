.cashout-container {
    display: flex;
    flex-direction: column;
    width: 500px;
    /* height: 300px; */
    margin: auto;
    padding: 30px;
    justify-content: space-between;
}

.cashout-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.cashout-paypal-image {
    height: 40px;
}

.cashout-middle-row {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.cashout-middle-row-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cashout-progress-bar {
    width: 100%;
    height: 15px;
    margin-bottom: 10px;
}

.bg-success {
    background-color: #00dd56!important;
}

.cashout-bottom-section{
    margin-top: 40px;
}

.cashout-button{
    margin-top: 40px;
}