.ribbon-contianer {}

.ribbon-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 40px;
}

.ribbon-image-label {
    display: flex;
    justify-content: center;
    position: relative;
}

.ribbon-top-image {
    z-index: 1;
}

.ribbon-title{
    text-align: center;
}

.ribbon-text {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-family: 'CircularBlack';
    color: #00D767;
    z-index: 1;
}

.ribbon-bottom-image {
    margin-top: -7px;
    z-index: 0;
}

@font-face {
    font-family: 'CircularBlack';
    src: url('../../Fonts//CircularStd-Black.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
}