.button-container {
    align-items: center;
    display: flex;
    cursor: pointer;
    padding: 10px 20px;
}

.button-style-yellow:hover {
    background-color: rgb(218, 180, 11);
}

.button-style-green:hover {
    background-color: rgb(2, 186, 91);
}

.button-style-grey:hover {
    background-color: rgb(219, 223, 230);
}

.button-style-purple {
    text-align: center;
}

.button-style-purple:hover {
    background-color: rgb(98, 52, 215);
}

.button-style-red:hover {
    background-color: rgb(222, 52, 72);
}

.button-style-locked:hover {
    background-color: rgb(0, 0, 0);
}

.button-text-left {
    margin: 0px;
    align-items: center;
}

.button-text-center {
    margin: 0px auto;
}

.button-style-multiple-choice-unselected {
    border: 2px solid rgb(211, 224, 240);
}

.button-style-multiple-choice-selected {
    border: 2px solid #09D776;
}