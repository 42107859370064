.daily-poll-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.daily-poll-voting-container {
    display: flex;
    flex-direction: column;
    padding: 30px 60px;
    width: 800px;
}

.daily-poll-button-container {
    display: flex;
    flex-direction: column;
}

.daily-poll-button {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    transition: all 0.23s ease-in-out;
}

.daily-poll-button:hover {
    background-color: rgb(244, 244, 244);
}

.daily-poll-button>p {
    margin: 10px 20px;
}

.daily-poll-button>* {
    z-index: 1;
}

.daily-poll-button-user-selected {
    border: 2px solid #00D767;
}

.daily-poll-button-user-not-selected {
    border: 2px solid rgb(211, 224, 240);
}

.daily-poll-button-unselected {
    border: 2px solid rgb(211, 224, 240);
}

.daily-poll-button-background-unselected {
    background: rgb(228, 236, 245);
}

.daily-poll-bottom-stats {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.daily-poll-line-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.daily-poll-line-top {
    flex: 1;
}

.daily-poll-line-bottom {
    flex: 1;
    border-top: 2px solid rgb(223, 223, 223);
}

.daily-poll-stat-item {
    display: flex;
    flex-direction: column;
    margin-top: -6px;
}

.daily-poll-stat-item>p {
    margin-left: 40px;
    margin-bottom: -5px;
}

.daily-poll-button-container {
    margin-top: 30px;
}

.daily-poll-background {
    position: absolute;
    z-index: 0;
    height: 100%;
}

.daily-poll-background-light-green {
    background: #CCF7E1;
}

.daily-poll-flex-grow {
    display: flex;
    flex: 1;
}

.daily-poll-votable {
    cursor: pointer;
}