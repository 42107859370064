.spinner-container {
    width: 120px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    justify-content: space-around;
    animation: fadein 1s;
    /* Firefox */
    -moz-animation: fadein 1s;
    /* Safari and Chrome */
    -webkit-animation: fadein 1s;
    /* Opera */
    -o-animation: fadein 1s;
}

.spinner-container-absolute {
    width: 120px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    justify-content: space-around;
    animation: fadein 1s;
    /* Firefox */
    -moz-animation: fadein 1s;
    /* Safari and Chrome */
    -webkit-animation: fadein 1s;
    /* Opera */
    -o-animation: fadein 1s;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.spinner-icon {
    width: 30px;
    animation-name: rotate-s-loader;
    animation-iteration-count: infinite;
    animation-duration: 0.75s;
    animation-timing-function: linear;
    margin-top: 10px;
}

.spinner-text {}

/* Rotate animation */

@keyframes rotate-s-loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Fade in animation */

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {
    /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}