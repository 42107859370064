.quick-surveys-container {
    display: flex;
    flex-direction: column;
}

.quick-surveys-cell-container {
    display: flex;
    flex-flow: row wrap;
}

.quick-survey-cell {
    background-color: rgb(247, 248, 255);
    margin-right: 40px;
    width: 320px;
    height: 100px;
    display: flex;
    overflow: hidden;
    transition: all .2s ease-in-out;
}

.quick-survey-cell:hover {
    background-color: white;
}

.quick-survey-cell-image {
    height: 100%;
}

.quick-survey-cell-content {
    flex: 3;
    margin-left: 40px;
    margin-right: 40px;
    align-self: center;
}

.quick-survey-cell-topic-label {
    margin-bottom: 5px;
}

.quick-survey-cell-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.quick-survey-cell-stat-item {
    display: flex;
    flex-direction: column;
}

.quick-survey-cell-stat-item>p {
    /* Robin: Is this risky? */
    margin-bottom: -10px;
}

.quick-survey-claim-button {
    width: fit-content;
    margin: auto;
}