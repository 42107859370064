.survey-router-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100vh;
}

.survey-router-content {
    overflow: auto;
    display: flex;
    flex: 1;
}

.survey-router-scroll {
    flex: auto;
    max-width: 1200px;
    margin: auto;
}

.survey-nav-bar-container {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: 10px;
}

.survey-nav-bar-item {
    font-size: 18px;
    font-family: 'CircularBlack';
    color: rgb(24, 24, 24);
}

.survey-nav-bar-flex-grow {
    display: flex;
    flex-grow: 10;
}