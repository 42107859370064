.lucid-inventory-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.lucid-inventory-search {
    display: flex;
}

.lucid-table-search {
    display: flex;
}

.lucid-inventory-reload {
    margin-right: 10px;
    width: 50px;
}

.lucid-table-english-quals-container {
    background-color: aliceblue;
    padding: 60px;
}

.lucid-table-english-quals {
    margin-bottom: 40px;
}

.lucid-table-english-quals-title {
    font-size: 28px;
    font-family: 'CircularBlack';
    color: rgb(24, 24, 24);
    margin-left: 15px;
}

.lucid-table-english-quals-question {
    font-size: 18px;
    font-family: 'CircularBlack';
    color: rgb(24, 24, 24);
    margin-left: 15px;
}

.lucid-table-english-quals-precode {
    font-size: 12px;
    font-family: 'CircularBook';
    color: rgb(24, 24, 24);
    margin-left: 30px;
    margin-bottom: 10px;
}

@font-face {
    font-family: 'CircularBlack';
    src: url('../../../Fonts//CircularStd-Black.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'CircularBook';
    src: url('../../../Fonts/CircularStd-Book.otf') format('truetype');
    font-weight: regular;
    font-style: regular;
}

@font-face {
    font-family: 'CircularMedium';
    src: url('../../../Fonts/CircularStd-Medium.otf') format('truetype');
    font-weight: medium;
    font-style: medium;
}