.staging-card-container {
    display: flex;
    flex-direction: column;
    flex: 4;
    padding: 30px;
}

.staging-card-detail-image {
    width: 200px;
    margin: 0px auto;
}

.staging-card-info-text {
    margin: auto;
}

.staging-card-button-row {
    display: flex;
    flex-direction: row;
    width: 400px;
}

.staging-card-info-text-closed {
    margin-top: 40px;
    margin-bottom: 40px;
}

.staging-card-info-text-completed {
    margin-bottom: 40px;
}

.staging-card-info-text-start {
    margin-bottom: 20px;
}

.staging-card-button-padding {
    /* TODO: Robin, can you mix margin, width, height with Flexbox? Or is flexbox designed to kill those properties? */
    margin: auto;
    width: fit-content;
}

.staging-card-button-locked {
    width: fit-content;
}

.staging-card-title-text {
    margin-bottom: 0px;
}

.staging-card-subtitle-text {}

.staging-card-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0px 30px;
    margin-top: 70px;
}

.staging-card-content-closed {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    width: 400px;
}

.staging-card-content-locked {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    width: 500px;
}

.staging-card-content-started {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin: 0px 30px;
    width: 400px;
}

.staging-card-content-pre {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin: 0px 30px;
}

.staging-card-right-half {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.staging-card-image-row {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.image-container {
    display: flex;
}

.staging-card-detail-image-pre {
    margin: auto;
    margin-bottom: 40px;
    width: 100%;
    max-width: 130px;
    max-height: 100%
}

.staging-card-detail-image-started {
    margin: auto;
    max-width: 130px;
    max-height: 100%
}

.staging-card-complete-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 0px 30px;
}