.userlookup-transaction-table-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.userlookup-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.userlookup-searchbar {
    display: flex;
    /* background-color: blanchedalmond; */
}

.userlookup-searchbar-button {
    width: 200px;
}

.userlookup-search-results-container {
    display: flex;
    flex: auto;
    flex-direction: row;
    overflow: scroll;
}

.userlookup-user {
    border-radius: 25px;
    background-color: white;
    display: flex;
    min-width: 350px;
    padding: 10px;
    overflow: scroll;
}

.userlookup-table-container {
    margin-left: 40px;
    display: flex;
    flex: auto;
    flex-direction: column;
}

.userlookup-table-transactions {
    border-radius: 25px;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-basis: 0px;
    flex-grow: 1;
    margin-bottom: 20px;
}

.userlookup-table-cashouts {
    border-radius: 25px;
    padding: 20px;
    margin-top: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-basis: 0px;
    flex-grow: 1;
}