/* Modal */

.modal-backdrop {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal {
    position: fixed;
    z-index: 500;
    transition: all 1.3s ease-out;
    overflow: auto;
}

.modal-formal-question {
    margin: 20px;
    text-align: center;
}

.modal-backdrop-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: fit-content;
    padding: 30px;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.model-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 400px; */
    height: fit-content;
    padding: 30px;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.model-content-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: fit-content;
    padding: 30px;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}