@media only screen and (max-width: 500px) {
    .survey-container-root {
        width: 100vw;
        height: 100vh;
        position: absolute;
        padding: 30px;
    }
    .survey-over-container {
        margin: auto;
        margin-top: 30px;
        padding: 30px 60px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .survey-over-emoji {
        font-size: 100px;
        text-align: center;
    }
    .survey-over-text {
        text-align: center;
    }
}

@media only screen and (min-width: 501px) {
    .survey-container-root {
        width: 100vw;
        height: 100vh;
        position: absolute;
    }
    .survey-over-container {
        margin: auto;
        margin-top: 30px;
        padding: 30px 60px;
        display: flex;
        flex-direction: column;
        width: 600px;
    }
    .survey-over-emoji {
        font-size: 100px;
        text-align: center;
    }
    .survey-over-text {
        text-align: center;
    }
}