.shelf-container {
    padding-top: 15px;
    /* margin-bottom: 30px; */
}

.shelf-row {
    display: flex;
    flex-direction: row;
}

.shelf-row-grow {
    display: flex;
    flex-grow: 1;
}
.shelf-row-text{
    margin: 0px 40px;
    margin-bottom: 15px;
}