.survey-router-nav-bar {
    display: flex;
    flex: 0 0 70px;
    padding: 0px;
    z-index: 13;
}

.nav-bar-container {
    display: flex;
    flex: 1;
    align-items: center;
}

/* Set spacing between nav bar items */

.nav-bar-container>*+* {
    margin-left: 40px;
}

.nav-bar-section:hover {
    color: #1B143D;
    text-decoration: none;
}

.nav-bar-current-balance {
    margin: auto;
}

.nav-bar-current-balance:hover {
    color: #05b65b;
    text-decoration: none;
}

.nav-bar-profile {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.nav-bar-image {
    width: 30px;
    /* height: 30px; */
    margin-left: 10px;
    margin-top: -4px;
}

.profile-nav-item {}