.completed-container {
    overflow: auto;
}

.completed-surveys-container {
    margin: auto;
    max-width: 600px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.completed-surveys-content {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
}

.completed-surveys-title {
    margin-bottom: 20px;
}

.completed-survey-cell {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #EFF3F8;
}

.completed-survey-cell-info {
    display: flex;
    flex-direction: column;
}

.completed-survey-cell-grow {
    flex-grow: 1;
}

.completed-survey-cell-label {
    margin-bottom: 0px;
}

.completed-survey-cell-date {
    margin-bottom: 20px;
}

.completed-survey-cell-amount {
    display: flex;
}

.completed-survey-cell-amount-label {
    align-items: center;
    display: flex;
}