.user-attributes-container {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    overflow: auto;
}

.user-attributes-item-left {
    display: flex;
    flex-direction: column;
    flex: 3;
    height: fit-content;
}

.user-attributes-item-left-content {
    display: flex;
    flex-direction: column;
    flex: 3;
    padding: 30px;
    height: fit-content;
}

.profile-left-panel-item {
    margin-top: 5px;
    text-decoration: underline;
}

.user-attributes-item-right {
    display: flex;
    flex: 10;
    flex-direction: column;
    margin-left: 30px;
}

.user-attributes-item-right-content {
    display: flex;
    flex: 10;
    flex-direction: column;
    padding: 40px;
    padding-top: 10px;
    overflow: auto;
}

.profile-left-panel-items {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    cursor: pointer;
}

.user-attributes-title {
    margin-bottom: 20px;
}

.user-attribute-cell {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    /* margin-bottom: 20px; */
    border-bottom: 1px solid #EFF3F8;
    padding-bottom: 20px;
}

.user-attribute-cell-grow {
    flex-grow: 1;
    min-width: 200px;
}

.user-attribute-cell-label {
    margin-bottom: 10px;
}

.user-attribute-cell-response {
    margin-bottom: 20px;
    text-align: end;
}

.user-attribute-cell-selection {
    flex: 1;
}

.user-attribute-cell-edit {
    margin-left: 15px;
    align-self: center;
    margin-bottom: 20px;
    min-width: 30px;
    cursor: pointer;
}