.admin-dashboard-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}

.admin-dashboard-side-pane {
    display: flex;
    background-color: white;
    min-width: 200px;
}

.admin-dashboard-side-conent {
    padding: 20px;
    display: flex;
    background-color: rgb(246, 244, 250);
    flex: auto;
    overflow: scroll;
}