.sweepstake-surveys-super-container {
    display: flex;
    flex: 1;
}

.sweepstake-surveys-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.sweepstake-surveys-title {}

.sweepstake-surveys-cell-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.sweepstake-survey-pane-container {
    display: flex;
    flex: 1;
    z-index: 11;
    overflow: auto;
    flex-basis: 0px;
}

.sweepstake-survey-table-view {
    flex: 0 0 380px;
    flex-direction: column;
    overflow-y: scroll;
    transition: all 0.23s ease-in-out;
}

.sweepstake-survey-table-view-in-progress {
    flex: 0;
    flex-direction: column;
    overflow-y: scroll;
    transition: all 0.23s ease-in-out;
}

.sweepstake-survey-table-view>*+* {
    margin-top: 20px;
}

.sweepstake-survey-table-view::-webkit-scrollbar {
    /* make scrollbar transparent */
    width: 0px;
    background: transparent;
}

.sweepstake-survey-detail-container {
    display: flex;
    flex-direction: column;
    flex: 4;
    transition: all 0.23s ease-in-out;
}

.sweepstake-survey-detail {
    display: flex;
    flex-direction: column;
    flex: 4;
    padding: 0px 100px;
}

.sweepstake-survey-cell {
    display: flex;
    flex-direction: row;
    color: black;
    height: 130px;
    overflow: hidden;
    transition: all .1s ease-in-out;
    border-color: rgb(147, 242, 193);
    transform-origin: top left;
}

.sweepstake-survey-cell-active {
    border-style: solid;
    border-width: 3px;
    border-color: #FFD51E;
}

.sweepstake-survey-cell-inactive {
    border-width: 0px;
    transform: scale(1);
}

.sweepstake-survey-cell:hover {
    opacity: 1;
    background-color: white;
}

.sweepstake-survey-cell-prize {
    display: flex;
    flex-direction: column;
    width: 170px;
    align-items: center;
    justify-content: center;
}

.sweepstake-survey-cell-prize-amount {
    margin-bottom: -10px;
}

.sweepstake-survey-cell-stats {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 20px;
}

.sweepstake-surveys-modal {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(24, 24, 24, 0.5);
    z-index: 10;
}