.userprofile-attribute-text {
    font-size: 14px;
    font-family: 'CircularBook';
    color: rgb(37, 37, 37);
    margin: 0px;
    margin-bottom: 3px;
}

.userprofile-attribute-value-text {
    font-size: 16px;
    font-family: 'CircularBlack';
    color: rgb(24, 24, 24);
    margin-bottom: 15px;
}

.userprofile-break {
    margin-bottom: 60px;
}

@font-face {
    font-family: 'CircularBlack';
    src: url('../../../Fonts//CircularStd-Black.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'CircularBook';
    src: url('../../../Fonts/CircularStd-Book.otf') format('truetype');
    font-weight: regular;
    font-style: regular;
}

@font-face {
    font-family: 'CircularMedium';
    src: url('../../../Fonts/CircularStd-Medium.otf') format('truetype');
    font-weight: medium;
    font-style: medium;
}